._2KIbFLIlauicQdb_NOTGoK, ._3v10YDDzj_1R3MmXUYGYtO, ._2iycZb8VMULNzGDsBuLzEh:first-child, ._2iycZb8VMULNzGDsBuLzEh._1L6KBzmyWj98iSLFkvU2BE a, ._2iycZb8VMULNzGDsBuLzEh._3BRA0-jmwbVY9KaKg4-Axc a {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._2KIbFLIlauicQdb_NOTGoK, ._3v10YDDzj_1R3MmXUYGYtO {
  font-size: 1.75rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._2KIbFLIlauicQdb_NOTGoK, ._3v10YDDzj_1R3MmXUYGYtO {
      font-size: 2.5rem;
      line-height: 1.7; } }

._1SZr-EHeiDczQSjomc1D5O, ._2XLNg6jdsIuyULEWShvIIE, ._2iycZb8VMULNzGDsBuLzEh._1L6KBzmyWj98iSLFkvU2BE a, ._2iycZb8VMULNzGDsBuLzEh._3BRA0-jmwbVY9KaKg4-Axc a {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._1SZr-EHeiDczQSjomc1D5O, ._2XLNg6jdsIuyULEWShvIIE, ._2iycZb8VMULNzGDsBuLzEh._1L6KBzmyWj98iSLFkvU2BE a, ._2iycZb8VMULNzGDsBuLzEh._3BRA0-jmwbVY9KaKg4-Axc a {
      font-size: 1.25rem;
      line-height: 1.8; } }

._2S6VP6w6E0v6RWSP4oLxnm {
  background: linear-gradient(-135deg, rgba(57, 150, 197, 0.6) 0%, rgba(40, 105, 138, 0.6) 5%, rgba(28, 75, 98, 0.6) 15%, #030b0d 35%); }

._2S6VP6w6E0v6RWSP4oLxnm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8.563rem 2rem 9.063rem 2rem;
  margin-top: -5rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  background: #030b0d;
  z-index: 1;
  -webkit-transition: background 0.25s ease;
  transition: background 0.25s ease; }
  .bvcEfNUIiCcuK3t8al4wS {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    position: relative; }
    ._1_1FNbEau-wSGj0gAC-MN1 {
      display: inline-block;
      max-width: 100%;
      height: auto; }
    ._2dLHodm9ohwOFTvDXHYEKW {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row;
              flex-flow: row;
      grid-gap: 1.5rem;
      gap: 1.5rem;
      margin-left: 4rem; }
      ._1q-BOlIBu7ZxyA3CDDlryv {
        font-size: 0.8rem;
        font-weight: bold;
        margin-top: -0.5rem; }
    ._1Jd4yhMlPHB3UdapMQhEub {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row;
              flex-flow: row;
      list-style: none;
      padding: 0;
      grid-gap: 1.5rem;
      gap: 1.5rem; }
      ._27Bzo8rwO8cTIQbDajOQSL {
        text-align: center; }
      ._227HT95GHZDrSJeFAhjKDD {
        max-height: 30px; }
    @media all and (max-width: 1200px) {
      .bvcEfNUIiCcuK3t8al4wS {
        display: none; } }
  @media all and (max-width: 1200px) {
    ._3Zc9Qab8aefEMFmicSqknX {
      display: inline-block;
      text-align: center;
      margin-bottom: 1.5rem; } }
  ._25bscpf3FusLD47bMB-DSD {
    display: inline-block;
    height: auto;
    margin-bottom: 1.25rem; }
    @media all and (max-width: 1200px) {
      ._25bscpf3FusLD47bMB-DSD {
        text-align: center; } }
  ._1yMePqYh0JHgnA61fNbuOD {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    grid-gap: 2rem;
    gap: 2rem;
    height: auto;
    margin-bottom: 1.25rem; }
    @media all and (max-width: 1200px) {
      ._1yMePqYh0JHgnA61fNbuOD {
        text-align: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center; } }
    @media all and (max-width: 340px) {
      ._1yMePqYh0JHgnA61fNbuOD {
        grid-gap: 1rem;
        gap: 1rem; } }
    ._1HF-attIeORNxkNZOUn4FC {
      max-width: 100%;
      width: 18%;
      border-right: 2px solid #030b0d;
      padding-right: 2rem; }
      @media all and (max-width: 390px) {
        ._1HF-attIeORNxkNZOUn4FC {
          width: 25%;
          padding-right: 1rem; } }
      @media all and (max-width: 280px) {
        ._1HF-attIeORNxkNZOUn4FC {
          width: 30%; } }
    ._1yMePqYh0JHgnA61fNbuOD > div {
      width: auto; }
  ._2KIbFLIlauicQdb_NOTGoK, ._3v10YDDzj_1R3MmXUYGYtO {
    margin: 0;
    padding: 0;
    color: #f9f9f9;
    font-weight: bold;
    word-break: break-word; }
    @media all and (max-width: 1200px) {
      ._2KIbFLIlauicQdb_NOTGoK, ._3v10YDDzj_1R3MmXUYGYtO {
        text-align: center; } }
  ._1SZr-EHeiDczQSjomc1D5O {
    margin: 0;
    padding: 0;
    margin-top: 2.188rem;
    color: #a9b2be;
    word-break: break-word; }
  @media all and (max-width: 1200px) {
    ._1SZr-EHeiDczQSjomc1D5O, ._2XLNg6jdsIuyULEWShvIIE {
      text-align: center; } }
  ._2XLNg6jdsIuyULEWShvIIE {
    color: #b3b3b3; }
  ._1YAXWvKsrrIIkWvt0j4bFb {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 0.938rem; }
    ._2XLNg6jdsIuyULEWShvIIE {
      margin: 0;
      padding: 0;
      margin-top: 0.938rem; }
      ._2XLNg6jdsIuyULEWShvIIE:before {
        display: inline-block;
        vertical-align: middle;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #f9f9f9;
        margin-right: 1.25rem;
        content: ''; }
      ._2XLNg6jdsIuyULEWShvIIE:first-child {
        margin-top: 0; }
  ._1QT0cDfhfXFMSmOay06scb {
    margin-top: 0.5rem; }
    @media all and (max-width: 1200px) {
      ._1QT0cDfhfXFMSmOay06scb {
        text-align: center; } }
    ._1QT0cDfhfXFMSmOay06scb a:hover {
      color: #0f679a;
      text-decoration: underline; }
  .Jca6wFRpLtYjL_3H-dmh5 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 2.5rem; }
    ._2iycZb8VMULNzGDsBuLzEh {
      margin-bottom: 1rem; }
      ._2iycZb8VMULNzGDsBuLzEh:first-child {
        margin-left: 0;
        font-size: 1.125rem;
        text-align: center; }
        @media all and (max-width: 750px) {
          ._2iycZb8VMULNzGDsBuLzEh:first-child {
            margin-top: 0; } }
      ._2iycZb8VMULNzGDsBuLzEh:last-child:not(:first-child) {
        margin-left: 1rem; }
      @media all and (max-width: 750px) {
        ._2iycZb8VMULNzGDsBuLzEh {
          margin-left: 0; } }
      ._2iycZb8VMULNzGDsBuLzEh._1L6KBzmyWj98iSLFkvU2BE a {
        text-align: center;
        border-radius: 80px;
        margin: 0;
        padding: 0.5rem 2rem;
        background: #1a70d4;
        background: linear-gradient(127deg, #1a70d4 13%, #2591cf 100%);
        word-break: break-word; }
        ._2iycZb8VMULNzGDsBuLzEh._1L6KBzmyWj98iSLFkvU2BE a:hover {
          background: linear-gradient(-127deg, #1a70d4 13%, #2591cf 100%); }
      ._2iycZb8VMULNzGDsBuLzEh._3BRA0-jmwbVY9KaKg4-Axc a {
        text-align: center;
        border-radius: 80px;
        margin: 0;
        padding: 0.5rem 2rem;
        background: #00a69c;
        word-break: break-word; }
        ._2iycZb8VMULNzGDsBuLzEh._3BRA0-jmwbVY9KaKg4-Axc a:hover {
          background: #00877f; }
        ._2iycZb8VMULNzGDsBuLzEh._3BRA0-jmwbVY9KaKg4-Axc a:active {
          background: #007d76; }
    @media all and (max-width: 1200px) {
      .Jca6wFRpLtYjL_3H-dmh5 {
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center; } }
  .TyWkiijDhwcoIs_6kNLBR {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid #fff;
    border-radius: 50%;
    height: 120px;
    width: 120px;
    cursor: pointer;
    position: absolute;
    top: 30%;
    -webkit-transition: 0.3s -webkit-transform;
    transition: 0.3s -webkit-transform;
    transition: 0.3s transform;
    transition: 0.3s transform, 0.3s -webkit-transform; }
    .s5f2qDdg4rdSh0SXauTc3 {
      color: #fff;
      font-size: 3.75rem; }
    .TyWkiijDhwcoIs_6kNLBR:hover {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
  ._2aTxljT0pTTngUeiWCNJHT {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    max-width: 665px; }
  .pWJO92HkcReNrb8mLgSBG {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    max-width: 1400px;
    position: relative;
    z-index: 5;
    grid-gap: 4.5rem;
    gap: 4.5rem;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    @media all and (max-width: 1440px) {
      .pWJO92HkcReNrb8mLgSBG {
        grid-template-columns: 1fr 0.8fr; } }
    @media all and (max-width: 1200px) {
      .pWJO92HkcReNrb8mLgSBG {
        grid-template-columns: 1fr; } }
  .C_Foe5zVo8CZ7vTyceq9T {
    background-image: url(/_next/static/images/purple-bg-d9c421c72908c524d1c9af1525272818.jpg);
    background-position-x: right;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-color: #5b5ef0; }
    .C_Foe5zVo8CZ7vTyceq9T:before {
      display: block;
      width: 2000px;
      height: 875px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAANrBAMAAAB1vO8bAAAAKlBMVEVkXvBpXfFyWvFeXvF/UvONRfZtXPGJSfWCT/R8VPN2WPJ5VvKGTPSQQvffglLJAAAANElEQVQ4y2MwGLRwOAKBIQkTBgwqDEm4gIZwA83gBBpCh0EAG2gID1AZFgwQDBgweIGaEACXOh6ASIfqyQAAAABJRU5ErkJggg==) repeat-x center transparent;
      position: absolute;
      right: 1511px;
      bottom: 0;
      z-index: 3;
      content: ''; }
  ._3GyEjfYi41KS4AtVZOyrtg {
    background: #fff; }
    ._3GyEjfYi41KS4AtVZOyrtg ._3v10YDDzj_1R3MmXUYGYtO, ._3GyEjfYi41KS4AtVZOyrtg ._1SZr-EHeiDczQSjomc1D5O {
      color: #030b0d; }
    ._3GyEjfYi41KS4AtVZOyrtg h1 {
      color: #030b0d; }
  ._3iP3kGljGtAnzzXQOYvnKP {
    min-height: calc(100vh - 7rem);
    padding: 5rem 2rem 2rem; }
    @media all and (max-width: 750px) {
      ._3iP3kGljGtAnzzXQOYvnKP {
        min-height: unset; } }
  @media all and (max-width: 750px) {
    ._2S6VP6w6E0v6RWSP4oLxnm {
      padding: 6.25rem 2rem 3.75rem 2rem; } }

._2j-COLQITyjlprIQvaLDTH {
  font-size: 0.8rem; }
