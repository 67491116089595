._2pkh0iCgX3pfjoytci2nfy, ._11gx4nFt-04VOCjqrrEspU {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._11gx4nFt-04VOCjqrrEspU._1ViAoEKysu8x5ZlArmAnsz {
  font-family: 'Hind', sans-serif; }

._25SDU7QaWFOEjwV5Op8EdX {
  background: #030b0d;
  padding: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  margin-top: -0.063rem; }
  @media all and (min-width: 768px) {
    ._25SDU7QaWFOEjwV5Op8EdX {
      padding: 7.5rem 0; } }
  ._3fqdiN0xmWCVF2bwVFkZtN {
    margin-bottom: 0;
    text-align: center;
    word-break: break-word; }
    @media (max-width: 991px) {
      ._3fqdiN0xmWCVF2bwVFkZtN {
        font-size: 1.75rem;
        line-height: 1.6;
        margin-top: 3rem; } }
  ._25cyyhALYczza8R6ojyMA_ {
    background: #fff; }
    ._25cyyhALYczza8R6ojyMA_ ._2pkh0iCgX3pfjoytci2nfy {
      color: #fbb703; }
    ._25cyyhALYczza8R6ojyMA_ ._11gx4nFt-04VOCjqrrEspU {
      color: #030b0d; }
  .rIHPmLgK23FEfcfUumm9X {
    background: #ffd15c; }
    .rIHPmLgK23FEfcfUumm9X ._11gx4nFt-04VOCjqrrEspU, .rIHPmLgK23FEfcfUumm9X ._2pkh0iCgX3pfjoytci2nfy {
      color: #030b0d; }
  .pYu61o_6DRlcBnJJxP_OT {
    background: #0f191e; }
    .pYu61o_6DRlcBnJJxP_OT ._3fqdiN0xmWCVF2bwVFkZtN {
      color: #fafbfc; }
    .pYu61o_6DRlcBnJJxP_OT ._2pkh0iCgX3pfjoytci2nfy {
      color: #9146ff; }
    .pYu61o_6DRlcBnJJxP_OT ._11gx4nFt-04VOCjqrrEspU {
      color: #fafbfc; }
  ._1WuxGrr-eBN-HY_WEl5hhd {
    background: #0f191e; }
    ._1WuxGrr-eBN-HY_WEl5hhd ._3fqdiN0xmWCVF2bwVFkZtN {
      color: #fafbfc; }
    ._1WuxGrr-eBN-HY_WEl5hhd ._2pkh0iCgX3pfjoytci2nfy {
      color: #ff4b55; }
    ._1WuxGrr-eBN-HY_WEl5hhd ._11gx4nFt-04VOCjqrrEspU {
      color: #fafbfc; }
  ._38D3Io5CqhH_Z9zt_yfJ0U {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 2rem 0;
    max-width: 1400px;
    margin: 0 auto; }
  ._1R11xv0PM4zErgCYCGcPJ- {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
    padding: 0;
    text-align: center;
    margin-bottom: 3rem;
    position: relative; }
    @media all and (min-width: 768px) {
      ._1R11xv0PM4zErgCYCGcPJ- {
        padding: 2rem 3.5rem;
        width: 434px;
        margin: 0; } }
  .RmDunyU_rQaRbXc7MgFA8 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    @media (max-width: 991px) {
      .RmDunyU_rQaRbXc7MgFA8 {
        height: unset; } }
  ._168cAEv6xrGZrOrTi84z8N {
    max-height: 28vh;
    height: 100%;
    margin-bottom: 2rem; }
    @media (max-width: 991px) {
      ._168cAEv6xrGZrOrTi84z8N {
        max-height: 21vh; } }
  ._2pkh0iCgX3pfjoytci2nfy {
    line-height: 1.8;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 0;
    margin-top: 1rem;
    font-weight: bold;
    word-break: break-word; }
  ._11gx4nFt-04VOCjqrrEspU {
    color: #fafbfc;
    line-height: 1.8;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0;
    margin-top: 1rem;
    word-break: break-word; }
    ._11gx4nFt-04VOCjqrrEspU._1ViAoEKysu8x5ZlArmAnsz {
      font-size: 1.25rem;
      font-weight: normal; }
  ._2pkh0iCgX3pfjoytci2nfy {
    color: #2891d0; }
  ._137fMHS1yUqYb_rjeyrLRL {
    display: inline-block; }
    ._137fMHS1yUqYb_rjeyrLRL:nth-of-type(2) {
      margin-left: 0rem; }
      @media all and (min-width: 768px) {
        ._137fMHS1yUqYb_rjeyrLRL:nth-of-type(2) {
          margin-left: 1rem; } }
  ._27-9RAYbMLmRrv_xjpH3-S {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 2rem;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column; }
    @media all and (min-width: 768px) {
      ._27-9RAYbMLmRrv_xjpH3-S {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row;
                flex-flow: row; } }
  ._2yHOcEj-i1o3PbWC3YAxnv {
    position: absolute;
    right: 0;
    width: 100%;
    max-width: 25px;
    margin-top: -2rem; }
    @media all and (min-width: 768px) {
      ._2yHOcEj-i1o3PbWC3YAxnv {
        max-width: 75px; } }
  ._2Nl5dYYOKKQN-HAgosf28M {
    position: absolute;
    right: 0;
    width: 100%;
    max-width: 25px;
    margin-top: 12rem; }
    @media all and (min-width: 768px) {
      ._2Nl5dYYOKKQN-HAgosf28M {
        max-width: 75px; } }
  ._1tB5RAdkEs-9n7uTBJTMMN {
    position: absolute;
    left: 0;
    width: 100%;
    max-width: 25px;
    margin-top: 28rem; }
    @media all and (min-width: 768px) {
      ._1tB5RAdkEs-9n7uTBJTMMN {
        max-width: 75px; } }
  ._1cefXK7IKQj1Z685Bcm1q7 {
    position: absolute;
    left: 0;
    width: 100%;
    max-width: 25px;
    margin-top: -1rem; }
    @media all and (min-width: 768px) {
      ._1cefXK7IKQj1Z685Bcm1q7 {
        max-width: 75px; } }
