._309HvIzNJzDR_cD-4L8HXE ._1f5Ip4IGVr5n7i5srcFH7O {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._23XLX5S2Fd8WvSRSwF3vMs, ._309HvIzNJzDR_cD-4L8HXE ._1f5Ip4IGVr5n7i5srcFH7O {
  font-size: 1.75rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._23XLX5S2Fd8WvSRSwF3vMs, ._309HvIzNJzDR_cD-4L8HXE ._1f5Ip4IGVr5n7i5srcFH7O {
      font-size: 2.5rem;
      line-height: 1.7; } }

._3l3JOMci27cgKyhH8uStVM {
  padding: 5rem 2rem; }
  @media (max-width: 991px) {
    ._3l3JOMci27cgKyhH8uStVM {
      padding: 2rem; } }

._3pphDCkW1QPPUrbP-zgLc_ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 1400px;
  margin: auto;
  grid-gap: 2.5rem;
  gap: 2.5rem; }

._1vGQ-MddSdmsfBCKvNzP9S {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #1f1e1f;
  border-radius: 10px;
  padding: 0 4rem; }
  @media (max-width: 991px) {
    ._1vGQ-MddSdmsfBCKvNzP9S {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column;
      padding: 1.5rem;
      border-radius: 0;
      margin: 0 -2rem; } }

._15lAg8kRm-L1QFpqv_tnCJ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  grid-gap: 2rem;
  gap: 2rem;
  margin: 0 auto; }
  @media (max-width: 991px) {
    ._15lAg8kRm-L1QFpqv_tnCJ {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column;
      grid-gap: 0;
      gap: 0; } }

._23XLX5S2Fd8WvSRSwF3vMs {
  text-align: center;
  color: #fff;
  margin: 0.5rem 0;
  word-break: break-word; }

._322r5sNqr79sB72wxRnQqI {
  text-align: center;
  margin: 0 auto;
  font-size: 1.125rem;
  line-height: 1.8;
  max-width: 900px; }
  ._322r5sNqr79sB72wxRnQqI._3Z4YEejXbzedx8gb9bGUkO {
    max-width: unset;
    padding-right: 2rem; }
    @media (max-width: 991px) {
      ._322r5sNqr79sB72wxRnQqI._3Z4YEejXbzedx8gb9bGUkO {
        padding: 0; } }
  ._322r5sNqr79sB72wxRnQqI.RxmqTNd-qgrgqEluymrS_ {
    display: none; }
  ._322r5sNqr79sB72wxRnQqI a {
    color: #00b4d8;
    font-weight: 500; }
    ._322r5sNqr79sB72wxRnQqI a:hover {
      color: #009bb9;
      text-decoration: underline;
      font-weight: 600; }
    ._322r5sNqr79sB72wxRnQqI a:active {
      color: #0092af; }

._1516qpfxdg_dmXVMSzQS9h {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  grid-gap: 0.5rem;
  gap: 0.5rem; }
  ._1516qpfxdg_dmXVMSzQS9h.RxmqTNd-qgrgqEluymrS_ {
    display: none; }

.gjIz-9dVXULFMMWn2fQdm {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%; }
  .gjIz-9dVXULFMMWn2fQdm ._1pyjUm0HBFWp-MuXbP2p6z {
    background: #f2f5f8;
    border-radius: 10px;
    padding: 0.5rem 4rem;
    margin-bottom: 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    cursor: pointer; }
    @media (max-width: 991px) {
      .gjIz-9dVXULFMMWn2fQdm ._1pyjUm0HBFWp-MuXbP2p6z {
        padding: 1rem; } }
    .gjIz-9dVXULFMMWn2fQdm ._1pyjUm0HBFWp-MuXbP2p6z:hover {
      background: #e5ebf1; }
    .gjIz-9dVXULFMMWn2fQdm ._1pyjUm0HBFWp-MuXbP2p6z:active {
      background: #dee6ed; }
    .gjIz-9dVXULFMMWn2fQdm ._1pyjUm0HBFWp-MuXbP2p6z ._3vVqZnz2u3F0AihTwFPW3v {
      font-size: 1.3rem;
      font-weight: 600;
      line-height: 1.6;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row;
              flex-flow: row;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      word-break: break-word;
      margin: 0; }
      @media (max-width: 991px) {
        .gjIz-9dVXULFMMWn2fQdm ._1pyjUm0HBFWp-MuXbP2p6z ._3vVqZnz2u3F0AihTwFPW3v {
          font-size: 1.125rem; } }
    .gjIz-9dVXULFMMWn2fQdm ._1pyjUm0HBFWp-MuXbP2p6z ._322r5sNqr79sB72wxRnQqI {
      text-align: left;
      font-size: 1.125rem;
      line-height: 1.7;
      display: none;
      margin: 0.5rem 0; }
      @media (max-width: 991px) {
        .gjIz-9dVXULFMMWn2fQdm ._1pyjUm0HBFWp-MuXbP2p6z ._322r5sNqr79sB72wxRnQqI {
          font-size: 1rem; } }
      .gjIz-9dVXULFMMWn2fQdm ._1pyjUm0HBFWp-MuXbP2p6z ._322r5sNqr79sB72wxRnQqI._3Z4YEejXbzedx8gb9bGUkO {
        display: block; }

._3rgyhjdwdgGeCJzOThn0Iv {
  display: block;
  font-size: 1.125rem;
  font-weight: 600;
  color: #1e1f25;
  text-align: center;
  margin: 1rem auto; }
  ._3rgyhjdwdgGeCJzOThn0Iv a {
    color: #00b4d8;
    margin-right: 1.5rem;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    display: block; }
    @media all and (min-width: 396px) {
      ._3rgyhjdwdgGeCJzOThn0Iv a {
        display: inline-block; } }
    ._3rgyhjdwdgGeCJzOThn0Iv a:hover {
      color: #009bb9; }
      ._3rgyhjdwdgGeCJzOThn0Iv a:hover ._38u5EM17aZOTg3bmCeYpuv {
        margin-left: 0.938rem; }
    ._3rgyhjdwdgGeCJzOThn0Iv a:active {
      color: #0092af; }
  ._3rgyhjdwdgGeCJzOThn0Iv ._38u5EM17aZOTg3bmCeYpuv {
    font-weight: 600;
    position: absolute;
    margin-left: 0.313rem; }

.BMn3b2DolRrxvn5O8hGhB {
  font-size: 1.5rem;
  color: #fff;
  text-align: right;
  cursor: pointer; }
  @media (max-width: 991px) {
    .BMn3b2DolRrxvn5O8hGhB {
      text-align: center;
      font-size: 1.2rem; } }

._309HvIzNJzDR_cD-4L8HXE {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media (max-width: 991px) {
    ._309HvIzNJzDR_cD-4L8HXE {
      grid-gap: 0.5rem;
      gap: 0.5rem; } }
  ._309HvIzNJzDR_cD-4L8HXE ._1f5Ip4IGVr5n7i5srcFH7O {
    margin-left: auto;
    font-weight: 600;
    font-size: 2rem; }
