._17FyLFZNJuiDU7-FdCovKZ {
  font-family: 'Hind', sans-serif; }

._16UT6k7QteHY2XHZtaggMR {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .Qm6MPoHTiYRWBC1jQUI1a {
    margin-right: 0.3rem;
    pointer-events: none;
    width: 42px; }
    @media all and (min-width: 751px) {
      .Qm6MPoHTiYRWBC1jQUI1a {
        width: auto; } }
  ._17FyLFZNJuiDU7-FdCovKZ {
    display: block;
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 1.875rem;
    line-height: 30px;
    letter-spacing: -1px; }
    ._17FyLFZNJuiDU7-FdCovKZ._17ncUm6ZVZyQAmfrNlnk1n {
      color: #fff; }
    ._17FyLFZNJuiDU7-FdCovKZ._3kFpNrA3TljsR15QpUdw0N {
      color: #030b0d; }
    @media all and (max-width: 750px) {
      ._17FyLFZNJuiDU7-FdCovKZ {
        font-size: 1.5rem;
        line-height: 25px; } }
    @media all and (max-width: 500px) {
      ._17FyLFZNJuiDU7-FdCovKZ {
        font-size: 1.375rem;
        line-height: 22px; } }
  ._2XPqCF7pjx6dRTKQzcZudc {
    display: block;
    width: 2px;
    height: 30px;
    background: #fff;
    margin-bottom: 0.3rem; }
  ._2JuOKqV7roqtF0HaWqnpqG {
    display: block;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    padding-left: 0.625rem;
    position: relative; }
    ._2JuOKqV7roqtF0HaWqnpqG:first-child {
      padding-left: 0; }
  @media all and (max-width: 1200px) {
    ._16UT6k7QteHY2XHZtaggMR {
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; } }
  ._16UT6k7QteHY2XHZtaggMR ._3MDfzJeBWxNjNszJ1NKrBK {
    background: #e57e49;
    width: auto;
    height: auto;
    font-family: 'Hind', sans-serif;
    font-size: 0.5rem;
    color: #fff;
    font-weight: bold;
    line-height: 8px;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: middle;
    padding: 0.125rem 0.125rem 0.125rem 0.25rem;
    position: absolute;
    top: 1.8rem;
    right: 0;
    border-radius: 3px;
    overflow: hidden; }
